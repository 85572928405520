<template>
    <div>
        <div class="modal fade" id="MachineStatusModal" tabindex="-1" aria-labelledby="MachineStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="MachineStatusModalLabel" class="font-weight-bold">{{ModalTitle}}{{MachineCode}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                        </div>
                        
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {globalfunc} from '../../../../shared/GlobalFunction';
import { response } from '../../../../infrastructure/constant/response';
import { sweet_alert } from '../../../../infrastructure/constant/variable';

import machineService from '../Script/MachineServices.js';

export default {
    name: 'MachineStatusForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',

            Id : '',
            MachineCode : '',
            Status : '',
            StatusData : [],
        }
    },
    methods: {
        async statusClick(dataId, dataCode, dataStatus){
            this.ModalTitle = 'Edit Status Mesin: ';
            this.Id = dataId;
            this.MachineCode = dataCode;
            
            this.StatusData = await globalfunc.globalDropdown('ddl_status_machine');
            this.Status = dataStatus;
            
            window.$('#MachineStatusModal').modal('show');
        },
        async saveClick(){
            this.$loading(true);

            var data = await machineService.GetMachine(this.Id);
                    
            var MachineItemArray = this.$globalfunc.objectToArrayConverter(data.machine_item_list, "Machine-MachineItemSave")

            const machineData = {
                machine_id : data.machine_id,
                machine_code : data.machine_code,
                machine_name : data.machine_name,
                machine_type_id : data.machine_type_id,
                storage_id : data.storage_id,
                status : this.Status,
                additional_time_list : data.additional_time_list,
                detail : MachineItemArray
            }

            const variables = {
                id: parseInt(this.Id),
                data : machineData
            }
            
            this.$swal(sweet_alert.update_status).then((result) => {
                if (result.isConfirmed == true) {
                    machineService.editQuery(variables).then(res => {
                        this.$loading(false);
                        window.$('#MachineStatusModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            });
            this.$loading(false);
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>