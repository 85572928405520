<template>
    <div>
        <kendo-grid 
            ref="grid"
            :data-source="dataSource"
            :pageable="pageableConfig"
            :filterable="filterableConfig"
            :sortable="true"
            :columns="columns"
            :resizable="true"
            v-on:detailinit="detailInit"
        >
        </kendo-grid>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { globalfunc } from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

import machineService from '../Script/MachineServices.js';

export default {
    name: 'MachineGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick', 'statusClick'],
    data(){
        return{
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapField = [
                                { field: "created_at", new_field: "\"master_machine\".\"created_at\"" },
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter,swapField),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort,swapField)
                            }
                            const variables = {
                                paging : paging,
                            }
                            return { 
                                query: machineService.readQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function(response) {
                        if(response.data.GetMachine.machine == null){
                            return [];
                        }
                        else{
                            return response.data.GetMachine.machine;
                        }
                    },
                    total: function(response) {
                        if(response.data.GetMachine.machine == null){
                            return [];
                        }
                        else{
                            return response.data.GetMachine.total;
                        }
                    },
                    model: {
                        fields: {
                            created_at: {type: "proddate"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" },
            }),
            columns:  [
                { title: "Action", width: 200, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "machine_code", title: "Kode Mesin", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "machine_name", title: "Nama Mesin", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "machine_type_code", title: "Kode Tipe Mesin", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "machine_type_name", title: "Nama Tipe Mesin", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "storage_id", title: "Gudang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                    attributes:{style: "text-align:center;"}, 
                    filterable: {ui: 'datepicker'} 
                },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    mounted: function(){
        var editClick = this.$props.editClick;
        var deleteClick = this.$props.deleteClick;
        var statusClick = this.$props.statusClick;

        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.machine_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.machine_id, true);
        })
        
        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.machine_id);
        })
        
        GridElement.on("click", "#StatusButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            statusClick(dataItem);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionWithStatusChangesButton("Machine")
        },
        detailInit: async function (e) {            
            var detailData = await machineService.GetMachine(e.data.machine_id)
            var additionalTimeData = machineService.additionalTimeListParseData(detailData.additional_time_list);

            $('<div/>').appendTo(e.detailCell).html('<label class="form-label"><b>Penambahan Waktu</b></label>');
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "AdditionalTimeListGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(additionalTimeData);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'additional_time_reason', title: "Nama", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true },
                    { field: 'additional_time_value', title: "Durasi (Menit)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true, attributes:{ style: "text-align:right" }  },
                ],
            })
            $('<div/>').appendTo(e.detailCell).html('<hr>');

            $('<div/>').appendTo(e.detailCell).html('<label class="form-label"><b>Item Mesin</b></label>');
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "MachineItemDetailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(detailData.machine_item_list);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'product_code', title: "Kode Item", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true },
                    { field: 'item_name', title: "Nama Item", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true },
                    { field: 'base_uom', title: "Satuan Dasar", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true },
                    { field: 'processing_time', title: "Speed (Menit)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true, attributes:{ style: "text-align:right" } },
                ],
            });
        },
        changeStatus(status){
            this.dataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging,
                                status : status,
                            }
                            return { 
                                query: machineService.readQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function(response) {
                        if(response.data.GetMachine.machine == null){
                            return [];
                        }
                        else{
                            return response.data.GetMachine.machine;
                        }
                    },
                    total: function(response) {
                        if(response.data.GetMachine.machine == null){
                            return [];
                        }
                        else{
                            return response.data.GetMachine.total;
                        }
                    },
                    model: {
                        fields: {
                            created_at: {type: "date"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" },
            });
        }
    }
}
</script>

<style scoped>
</style>