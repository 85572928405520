<template>
    <div>
        <kendo-grid 
            ref="grid"
            :data-source="dataSource"
            :pageable="pageableConfig"
            :filterable="filterableConfig"
            :sortable="true"
            :columns="columns"
            :resizable="true"
            v-on:detailinit="detailInit"
        >
        </kendo-grid>
    </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

import machineTypeService from '../Script/MachineTypeServices.js';

export default {
    name: 'MachineTypeGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick'],
    data(){
        return{
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging,
                            }
                            return { 
                                query: machineTypeService.readQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function(response) {
                        if(response.data.GetMachineType.machine_type == null){
                            return [];
                        }
                        else{
                            return response.data.GetMachineType.machine_type;
                        }
                    },
                    total: function(response) {
                        if(response.data.GetMachineType.machine_type == null){
                            return [];
                        }
                        else{
                            return response.data.GetMachineType.total;
                        }
                    },
                    model: {
                        fields: {
                            created_at: {type: "proddate"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" },
            }),
            columns:  [
                { title: "Action", width: 200, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "machine_type_code", title: "Kode Tipe Mesin", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "machine_type_name", title: "Nama Tipe Mesin", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, filterable: {ui: 'datepicker'}},
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    mounted: function(){
        var editClick = this.$props.editClick;
        var deleteClick = this.$props.deleteClick;

        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.machine_type_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.machine_type_id, true);
        })
        
        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.machine_type_id);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Machine Type")
        },
        detailInit: async function (e) {
            var detailData = await machineTypeService.GetMachineType(e.data.machine_type_id)

            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "MachineTypeDetailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(detailData.machine_type_details);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'type_detail_id', title: "Kode Tipe Detail", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true },
                    { field: 'type_detail_name', title: "Nama Tipe Detail", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        editable: false, nullable: true },
                ],
            })
        },
    }
}
</script>

<style scoped>
</style>