<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Tipe Mesin</h2>
            </CCardHeader>
            <CCardBody>
                <!-- <span v-html="statusButton"></span> -->

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <machine-type-grid ref="grid" :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" />
                <machine-type-form ref="MachineTypeModal" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

import machineTypeGrid from '../MachineType/Grid/MachineTypeGrid.vue';
import machineTypeForm from '../MachineType/Component/MachineTypeForm.vue';
import machineTypeService from '../MachineType/Script/MachineTypeServices.js';

export default {
    name: 'MachineType',
    components: {
        'machine-type-grid': machineTypeGrid,
        'machine-type-form': machineTypeForm,
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Machine Type');
        document.getElementById("add").disabled = permission.cperms ? false : true;
    },
    data(){
        return{
            gridReload: 0,
        }
    },
    methods: {
        addClick(){
            this.$refs.MachineTypeModal.addClick();
        },
        editClick(id, view) {
            this.$refs.MachineTypeModal.editClick(id, view);
        },
        deleteClick(id) {
            this.$swal(sweet_alert.delete).then((result) => {
                if (result.isConfirmed == true) {
                    this.$loading(true);

                    const variables = {
                        id: parseInt(id),
                    }
                    
                    machineTypeService.deleteQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successDelete, "success");
                        this.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            });
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>

<style scoped>
</style>