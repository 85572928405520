<template>
    <div>
        <input hidden type="text" class="form-control" id="MachineTypeId" v-model="MachineTypeId">
        <div class="modal fade" id="MachineTypeModal" tabindex="-1" aria-labelledby="MachineTypeModalLabel" aria-hidden="true" style="overflow:auto;">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="MachineTypeModalLabel" class="font-weight-bold">{{ModalTitle}} Tipe Mesin : {{MachineTypeCode}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold required">Kode Tipe Mesin</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <CInput id="MachineTypeCode" v-model="MachineTypeCode" class="font-weight-bold" :disabled="DisabledInput" />
                                    <label id="errorMachineTypeCode" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                              
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold required">Nama Tipe Mesin</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <CInput id="MachineTypeName" v-model="MachineTypeName" class="font-weight-bold" />
                                    <label id="errorMachineTypeName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>

                            <div class="p-3 border rounded" style="width: 100%;">
                                <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Tipe Detail</h4></label>
                                <div class="row float-right pr-3">
                                    <button class="btn btn-primary"
                                        @click="addTypeDetailClick()">
                                        <i class="fa fa-plus"></i> Add
                                    </button>
                                </div>
                                <hr>
                                <datasource ref="TypeDetailDataSource" :data="this.TypeDetailGridData" :page-size="10" :schema-model-fields="this.TypeDetailGridDataSchemaModel"/>

                                <kendo-grid ref="gridTypeDetail"
                                    :data-source-ref="'TypeDetailDataSource'"
                                    :editable="true"
                                    :pageable="true"
                                    :resizable="true"
                                    :filterable="true"
                                    :sortable="true"
                                >

                                    <kendo-grid-column  :field="'machine_type_detail_id'"
                                                        :title="'Detail Id Tipe Mesin'"
                                                        :width="200"
                                                        :hidden="true"
                                                        :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                    <kendo-grid-column  :field="'type_detail_id'"
                                                        :title="'Kode Tipe Detail'"
                                                        :width="150"
                                                        :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                        
                                    <kendo-grid-column  :field="'type_detail_name'"
                                                        :title="'Nama Tipe Detail'"
                                                        :width="200"
                                                        :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                        
                                    <kendo-grid-column  :title="'Action'"
                                                        :attributes="{ class: 'k-text-center' }"
                                                        :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                        :command="['destroy']"
                                                        :width="100"></kendo-grid-column>
                                </kendo-grid>
                                
                                <type-detail-form ref="TypeDetailDataForm" :saveTypeDetailGrid="saveTypeDetailGrid"/>
                            </div>

                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import { response } from '../../../../infrastructure/constant/response';

import typeDetailForm from '../Component/TypeDetailDataForm.vue';
import machineTypeService from '../Script/MachineTypeServices.js';

export default {
    name: 'MachineTypeForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'type-detail-form' : typeDetailForm,
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle: '',

            MachineTypeId:'',
            MachineTypeCode:'',
            MachineTypeName:'',

            TypeDetailGridData: [],
            TypeDetailGridDataSchemaModel: {
                machine_type_detail_id: { type: "string", editable: false},
                type_detail_id: { type: "string", editable: false},
                type_detail_name: { type: "string", editable: false},
            },
            
            DisabledInput: false,
            SaveType: '',
            Error: 0,
        }
    },
    methods: {
        async addClick() {
            $(".form-error").css("display", "none");
            this.ModalTitle = 'Add';

            this.MachineTypeId = null;
            this.MachineTypeCode = 'Auto';
            this.MachineTypeName = '';
            this.TypeDetailGridData = [];
            
            this.SaveType = 'Add';
            
            $(".save-button").show();
            $(".add-button").show();
            window.$('#MachineTypeModal').modal('show');
        },
        async editClick(id, View){
            $(".form-error").css("display", "none");
            this.ModalTitle = 'Edit';

            var data = await machineTypeService.GetMachineType(parseInt(id));

            this.MachineTypeId = data.machine_type_id;
            this.MachineTypeCode = data.machine_type_code;
            this.MachineTypeName = data.machine_type_name;

            this.TypeDetailGridData = [];
            if (data.machine_type_details != null) {
                this.TypeDetailGridData = this.$globalfunc.objectToArrayConverter(data.machine_type_details, "MachineType-TypeDetailGet");
            }

            this.SaveType = 'Edit';
            
            if(View){
                $(".save-button").hide();
                $(".add-button").hide();
                this.ModalTitle = 'View';
            }
            else {
                $(".save-button").show();
                $(".add-button").show();
            }
            this.DisabledInput = true;
            window.$('#MachineTypeModal').modal('show');
        },
        addTypeDetailClick(){
            this.$refs.TypeDetailDataForm.addClick();
        },
        saveTypeDetailGrid(data){
            var gridData = this.$refs.gridTypeDetail.kendoWidget().dataSource._data;
            var TypeDetailData = machineTypeService.gridDataConverter(gridData, this.MachineTypeId);

            var inputData = machineTypeService.gridDataConverter(data, this.MachineTypeId);
            TypeDetailData = inputData.concat(TypeDetailData);

            this.TypeDetailGridData = Object.values(TypeDetailData.reduce((a, c) => (a[`${c.type_detail_id}`] = c, a), {}));
        },
        
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");
            
            if (this.MachineTypeName == null || this.MachineTypeName == '') {
                this.errorShow('errorMachineTypeName');
            }

            var TypeDetailGridData = this.$refs.gridTypeDetail.kendoWidget().dataSource._data;
            if ( TypeDetailGridData.length == 0){
                this.Error++;
                this.$swal("Error", "Data Tipe Detail harus di isi", "error");
            }
        },
        async saveClick() {
            this.inputValidation();

            if(this.Error == 0){
                var TypeDetailGridData = this.$refs.gridTypeDetail.kendoWidget().dataSource._data;
                var TypeDetailArray = this.$globalfunc.objectToArrayConverter(TypeDetailGridData, "MachineType-TypeDetailSave")

                const machineTypeData = {
                    machine_type_id : this.MachineTypeId,
                    machine_type_code : this.MachineTypeCode,
                    machine_type_name : this.MachineTypeName,
                    detail : TypeDetailArray
                }

                if (this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : machineTypeData
                    }

                    machineTypeService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#MachineTypeModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                } 
                else if (this.SaveType == 'Edit'){
                    this.$loading(true);
                    
                    const variables = {
                        id: parseInt(this.MachineTypeId),
                        data : machineTypeData
                    }
                    
                    machineTypeService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#MachineTypeModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>
