<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Mesin : {{MachineCode}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <CInput id="MachineId" v-model="MachineId" class="font-weight-bold" hidden/>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Kode Mesin</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="MachineCode" v-model="MachineCode" class="font-weight-bold" :disabled="DisabledInput"/>
                            <label id="errorMachineCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Nama Mesin</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="MachineName" v-model="MachineName" class="font-weight-bold" :disabled="DisabledInput" />
                            <label id="errorMachineName" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Tipe Mesin</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="MachineType" class="pb-3" :options="MachineTypeData" v-model="MachineType" :disabled="DisabledInput" />
                            <label id="errorMachineType" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>

                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Gudang</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Storage" class="pb-3" :options="StorageData" v-model="Storage" :disabled="DisabledInput" @input="onChangeStorage()" />
                            <label id="errorStorage" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Status" class="pb-3" :options="StatusData" v-model="Status" />
                            <label id="errorStatus" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>

                    <CRow class="p-3">
                        <div class="p-3 border rounded" style="width: 100%;">                            
                            <div class="py-2">
                                <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Tambahan Waktu</h4></label>
                            </div>

                            <datasource ref="AdditionalTimeDataSource" :data="this.AdditionalTimeGridData" :page-size="10" :schema-model-fields="this.AdditionalTimeGridDataSchemaModel"/>

                            <kendo-grid ref="gridAdditionalTime"
                                :data-source-ref="'AdditionalTimeDataSource'"
                                :editable="this.AdditionalTimeEditable"
                                :pageable="true"
                                :resizable="true"
                                :filterable="true"
                                :sortable="true"
                                :toolbar="this.AdditionalTimeToolbar"
                            >

                                <kendo-grid-column  :field="'additional_time_reason'"
                                                    :title="'Nama'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'additional_time_value'"
                                                    :title="'Durasi (Menit)'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :title="'Action'"
                                                    :attributes="{ class: 'k-text-center' }"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :command="['destroy']"
                                                    :width="200"
                                                    :hidden="this.HiddenAction"></kendo-grid-column>
                            </kendo-grid>
                        </div>
                    </CRow>
                    
                    <CRow class="p-3">
                        <div class="p-3 border rounded" style="width: 100%;">                
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Item Mesin</h4></label>
                            <div class="row float-right pr-3">
                                <button class="btn btn-primary"
                                    @click="addMachineItemClick()">
                                    <i class="fa fa-plus"></i> Add Item
                                </button>
                            </div>
                            <hr>
                            <datasource ref="MachineItemDataSource" :data="this.MachineItemGridData" :page-size="10" :schema-model-fields="this.MachineItemGridDataSchemaModel"/>

                            <kendo-grid ref="gridMachineItem"
                                :data-source-ref="'MachineItemDataSource'"
                                :editable="this.MachineItemEditable"
                                :pageable="true"
                                :resizable="true"
                                :filterable="true"
                                :sortable="true"
                            >

                                <kendo-grid-column  :field="'machine_item_list_id'"
                                                    :title="'Id Item Mesin'"
                                                    :width="200"
                                                    :hidden="true"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'machine_id'"
                                                    :title="'Id Mesin'"
                                                    :width="200"
                                                    :hidden="true"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'item_id'"
                                                    :title="'Id Item'"
                                                    :width="200"
                                                    :hidden="true"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'product_code'"
                                                    :title="'Kode Item'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'item_name'"
                                                    :title="'Nama Item'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'base_uom'"
                                                    :title="'Satuan Dasar'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'processing_time'"
                                                    :title="'Speed (Menit)'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{ style: 'text-align:right;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :title="'Action'"
                                                    :attributes="{ class: 'k-text-center' }"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :command="['destroy']"
                                                    :width="200"
                                                    :hidden="this.HiddenAction"></kendo-grid-column>
                            </kendo-grid>
                        </div>
                        
                        <machine-type-item-form ref="MachineItemDataForm" :saveMachineItemGrid="saveMachineItemGrid"/>
                    </CRow>
                    
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/machine')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import { response } from '../../../../infrastructure/constant/response';

import machineItemForm from '../Component/ItemListForm.vue';
import machineService from '../Script/MachineServices.js';

export default {
    name: 'MachineTypeForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'machine-type-item-form' : machineItemForm,
    },
    data() {
        return {
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Id: this.$route.params.id,
            Error : 0,

            ModalTitle: '',

            MachineId:'',
            MachineCode:'',
            MachineName:'',

            MachineType:'',
            MachineTypeData:[],
            Storage:'',
            StorageData:[],
            Status:'',
            StatusData:[],

            AdditionalTimeGridData: [],
            AdditionalTimeGridDataSchemaModel: {
                additional_time_reason: { type: "string", editable: true, validation: { required:true } },
                additional_time_value: { type: "number", editable: true, validation: { required:true, min:1 } },
            },

            MachineItemGridData: [],
            MachineItemGridDataSchemaModel: {
                machine_item_list_id: { type: "string", editable: false},
                machine_id: { type: "string", editable: false},
                item_id: { type: "string", editable: false},
                product_code: { type: "string", editable: false},
                item_name: { type: "string", editable: false},
                base_uom: { type: "string", editable: false},
                processing_time: { type: "number", editable: true, validation: { required:true, min:1 }},
            },
            
            SaveType: '',
            DisabledInput: false,
            HiddenAction: false,
            AdditionalTimeToolbar: ['create'],
            AdditionalTimeEditable: {createAt:'bottom'},
            MachineItemEditable: true,
        }
    },
    async mounted() {
        this.$loading(true);
        $(".form-error").css("display", "none");

        if (this.FormType == 'Add') {
            this.MachineId = null;
            this.MachineCode = 'Auto';
            this.MachineName = '';
            
            this.MachineTypeData = await machineService.GetMachineTypeQuery();
            this.MachineType = '';
            
            this.StorageData = await machineService.GetStorageQuery();
            this.Storage = this.StorageData.find(c => c.value == 'Produksi');

            this.StatusData = [
                { value:'New', label:'New' }
            ];
            this.Status = this.StatusData.find(c => c.value == 'New');
            
            this.AdditionalTimeGridData = [];
            this.MachineItemGridData = [];
            
            this.SaveType = 'Add';
            this.HiddenAction = false;
            
            $(".save-button").show();
            $(".add-button").show();
        }
        else {
            this.FormType = 'Edit';

            var data = await machineService.GetMachine(this.Id);
            
            this.MachineId = data.machine_id;
            this.MachineCode = data.machine_code;
            this.MachineName = data.machine_name;
            
            this.MachineTypeData = await machineService.GetMachineTypeQuery();
            this.MachineType = this.MachineTypeData.find(c => c.value == data.machine_type_id);
            
            this.StorageData = await machineService.GetStorageQuery();
            this.Storage = this.StorageData.find(c => c.value == data.storage_id);

            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_machine');
            this.Status = this.StatusData.find(c => c.value == data.status);

            this.AdditionalTimeGridData = [];
            if (data.machine_item_list != null) {
                this.AdditionalTimeGridData = machineService.additionalTimeListParseData(data.additional_time_list);
            }
            
            this.MachineItemGridData = [];
            if (data.machine_item_list != null) {
                this.MachineItemGridData = this.$globalfunc.objectToArrayConverter(data.machine_item_list, "Machine-MachineItemGet");
            }

            this.SaveType = 'Edit';

            // if (data.status != 'New' ) {
            //     this.HiddenAction = true;
            //     this.AdditionalTimeToolbar = ['create'];
            //     this.AdditionalTimeEditable = {createAt:'bottom'};
            //     this.MachineItemEditable = true;
            //     $(".add-button").show();
            // }
            // else {
                this.HiddenAction = false;
                this.AdditionalTimeToolbar = ['create'];
                this.AdditionalTimeEditable = {createAt:'bottom'};
                this.MachineItemEditable = true;
                $(".add-button").show();
            // }
            this.DisabledInput = true;

            if(this.View == 'true'){
                this.FormType = 'View';
                $(".save-button").hide();
                $(".add-button").hide();
            }
            else {
                $(".save-button").show();
            }
        }

        this.$loading(false);
    },
    methods: {
        onChangeStorage() {
            this.MachineItemGridData = [];
        },
        addMachineItemClick(){
            if (this.MachineType.value === undefined) {
                this.$swal("Info", "Harap untuk memilih Tipe Mesin terlebih dahulu", "info");
            }
            else {
                this.$refs.MachineItemDataForm.addClick(this.MachineType.value);
            }
        },
        saveMachineItemGrid(data){
            var gridData = this.$refs.gridMachineItem.kendoWidget().dataSource._data;
            var MachineItemData = machineService.gridDataConverter(gridData, this.MachineId);

            var inputData = machineService.gridDataConverter(data, this.MachineId);
            MachineItemData = inputData.concat(MachineItemData);

            this.MachineItemGridData = Object.values(MachineItemData.reduce((a, c) => (a[`${c.item_id}`] = c, a), {}));
        },
        
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");
            
            if (this.MachineName == null || this.MachineName == '') {
                this.errorShow('errorMachineName');
            }
            if (this.MachineType.value === undefined) {
                this.errorShow('errorMachineType');
            }

            var AdditionalTimeGridData = this.$refs.gridAdditionalTime.kendoWidget().dataSource._data;
            if (AdditionalTimeGridData.length == 0) {
                this.Error++;
                this.$swal("Error", "Data Penambahan Waktu harus di isi", "error");
            }
            else {
                for(var i=0; i<AdditionalTimeGridData.length; i++){
                    if (AdditionalTimeGridData[i].additional_time_reason.trim() == '') {
                        this.$swal("Error", "Field Alasan pada List Penambahan Waktu harus di isi", "error");
                        this.Error++;
                        break;
                    }
                    if (AdditionalTimeGridData[i].additional_time_value == 0) {
                        this.$swal("Error", "Field Waktu pada List Penambahan Waktu harus di isi", "error");
                        this.Error++;
                        break;
                    }
                }
            }

            var MachineItemGridData = this.$refs.gridMachineItem.kendoWidget().dataSource._data;
            if (MachineItemGridData.length == 0) {
                this.Error++;
                this.$swal("Error", "Data Item Tipe Mesin harus di isi", "error");
            }
            else {                
                for(var i=0; i<MachineItemGridData.length; i++){
                    if (MachineItemGridData[i].processing_time == 0) {
                        this.$swal("Error", "Field Waktu pada List Item Tipe Mesin harus di isi", "error");
                        this.Error++;
                        break;
                    }
                }
            }
        },
        async saveClick() {
            this.inputValidation();

            if(this.Error == 0){
                var AdditionalTimeGridData = this.$refs.gridAdditionalTime.kendoWidget().dataSource._data;
                var AdditionalTimeJson = machineService.additionalTimeDataConverter(AdditionalTimeGridData);
                
                var MachineItemGridData = this.$refs.gridMachineItem.kendoWidget().dataSource._data;
                var MachineItemArray = this.$globalfunc.objectToArrayConverter(MachineItemGridData, "Machine-MachineItemSave")

                const machineData = {
                    machine_id : this.MachineId,
                    machine_code : this.MachineCode,
                    machine_name : this.MachineName,
                    machine_type_id : this.MachineType.value,
                    storage_id : this.Storage.value,
                    status : this.Status.value,
                    additional_time_list : AdditionalTimeJson,
                    detail : MachineItemArray
                }

                if (this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : machineData
                    }

                    machineService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/machine' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                } 
                else if (this.SaveType == 'Edit'){
                    this.$loading(true);
                    
                    const variables = {
                        id: parseInt(this.MachineId),
                        data : machineData
                    }
                    
                    machineService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success").then(
                            this.$router.push({ path: '/machine' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>

<style scoped>
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>