<template>
    <div>
        <div class="modal fade" id="MachineItemModal" tabindex="-1" aria-labelledby="MachineItemModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content border border-primary">
                    <div class="modal-header">
                        <h4 id="MachineItemModalLabel" class="font-weight-bold">Add Item Tipe Mesin</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <kendo-grid ref="grid"
                            :data-source="dataSource"
                            :pageable="pageableConfig"
                            :columns="columns"
                            :resizable="true"
                            :filterable="filterableConfig"
                            :sortable="true"
                            v-on:databound="dataBound"
                        >
                        </kendo-grid>
                        
                        <br>
                        <CButton type="button" @click="saveClick()" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
        <input hidden id="hf_ID_Filter" v-model="hf_ID_Filter" style="float: right; width : 80%; height:37px">
        <input hidden id="hf_CheckAll_Filter" v-model="hf_CheckAll_Filter"  style="float: right; width : 20%; height:37px; text-align: center;"/>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { globalfunc } from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid } from '../../../../infrastructure/constant/variable';

import machineService from '../Script/MachineServices.js';

export default {
    name: 'MachineItemDataForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['saveMachineItemGrid'],
    mounted:  function () {
        var vueComponent = this;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("change", ".cbFilter", function (e) {
            e.preventDefault();
            var checked = this.checked;
            var rowElement = grid.tbody.find(e.target.closest("tr"));
            var row = grid.dataItem(e.target.closest("tr"));
            var hfIDValue = vueComponent.hf_ID_Filter;
            var uid = row.uid;
            var dataItem = row.item_id + "|" + row.product_code + "|" + row.item_name + "|" + row.base_uom; 
           
            var checkedIdDistributorArray = hfIDValue.split(";");

            var lastvalueHfid =checkedIdDistributorArray.slice(-1)[0];
            var hf_CheckAll_Filter = vueComponent.hf_CheckAll_Filter;
           
            if (checked)
                grid.tbody.find("tr[data-uid='" +uid+ "']").addClass("k-state-selected");
            else
                grid.tbody.find("tr[data-uid='" +uid+ "']").removeClass("k-state-selected");
            
            var lastIndex = (hfIDValue.length - dataItem.length)
            if (!checked && hf_CheckAll_Filter == "true") {
            if (hfIDValue == "")
               hfIDValue = dataItem;
                else {
                    //Check if not exist
                    if (hfIDValue.indexOf(dataItem) == "-1")
                        hfIDValue = hfIDValue + ";" + dataItem;
                }
             }
            else if (!checked && hf_CheckAll_Filter == "false" || hf_CheckAll_Filter == "") {    
                if(lastvalueHfid != dataItem.toString())
                {
                    hfIDValue = hfIDValue.replace(dataItem + ";", '');
                }
                else if (lastvalueHfid == dataItem.toString()) 
                {
                    hfIDValue = hfIDValue.replace(";" + dataItem, '');  
                }
                else
                {
                    hfIDValue = hfIDValue.replace(dataItem, '');
                }
                //Check kalau bukan di index terakhir
                if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) != lastIndex && hfIDValue.indexOf(";") != "-1")
                    hfIDValue = hfIDValue.replace(dataItem + ";", '');
                //Check di index terakhir
                else if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) == lastIndex && hfIDValue.indexOf(";") != "-1")
                    hfIDValue = hfIDValue.replace(";" + dataItem, '');   
                else
                    hfIDValue = hfIDValue.replace(dataItem, '');
            }
            else if (checked && hf_CheckAll_Filter == "true") {
                //Check kalau bukan di index terakhir
                if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) != lastIndex && hfIDValue.indexOf(";") != "-1")
                    hfIDValue = hfIDValue.replace(dataItem + ";", '');
                //Check di index terakhir
                else if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) == lastIndex && hfIDValue.indexOf(";") != "-1")
                    hfIDValue = hfIDValue.replace(";" + dataItem, '');
                else
                    hfIDValue = hfIDValue.replace(dataItem, '');
            }
            else if (checked && hf_CheckAll_Filter == "false" || hf_CheckAll_Filter == "") {
                if (hfIDValue == "")
                    hfIDValue = dataItem;
                else {
                    if(!checkedIdDistributorArray.includes(dataItem))
                    {
                        hfIDValue = hfIDValue + ";" + dataItem;
                    }
                }
            }
            if (checked) {
                rowElement.addClass("k-state-selected");
            } else {
                rowElement.removeClass("k-state-selected");
            }
            vueComponent.hf_ID_Filter = hfIDValue
        });        
    },
    data: function () {
        return {
            hf_CheckAll_Filter:"false",
            hf_ID_Filter:"",
            dataSource: [],
            columns: [
                { template: this.columnTemplate, width: 40, headerAttributes:{ style: "text-align:center;" }, attributes: { style:"text-align:center;" } },
                { field: 'item_id', title: "ID Item", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, hidden:true },
                { field: 'product_code', title: "Kode Item", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'item_name', title: "Nama Item", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'base_uom', title: "Satuan Dasar", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'type_detail_id', title: "Tipe Detail", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    methods: {
        headerTemplate() {
            return `<input type="checkbox" id="masterCheckBoxFilter"" />`
        },
        columnTemplate() {
            return `<input type="checkbox" class="cbFilter" />`
        },
        addClick(data){
            var MachineTypeId = data;
            this.dataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging,
                                id : MachineTypeId
                            }
                            return { 
                                query: machineService.GetMachineItemDataQuery(),
                                variables : variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if (response.data.GetMachineTypeItemList.items == null) {
                            return [];
                        }
                        else {
                            return response.data.GetMachineTypeItemList.items;
                        }
                    },
                    total: function (response) {
                        if (response.data.GetMachineTypeItemList.items == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetMachineTypeItemList.total;
                        }
                    },                    
                    model: {
                        fields: {
                            item_id: { type: "string",editable: false },
                            product_code: { type: "string",editable: false },
                            item_name: { type: "string",editable: false },
                            base_uom: { type: "string",editable: false },
                        }
                    }
                },
            });
            this.hf_CheckAll_Filter = "false";
            this.hf_ID_Filter = "";
            window.$('#MachineItemModal').modal('show');
        },
        saveClick(){
            if (this.hf_ID_Filter == '') {
                this.$swal("Info", "Harap untuk Tipe Detail", "info");
            }
            else {
                var selectedData = machineService.selectedMachineItemConverter(this.hf_ID_Filter);
                this.$props.saveMachineItemGrid(selectedData);
                window.$('#MachineItemModal').modal('hide');
            }
        },
        dataBound: function (e) {
            var grid = this.$refs.grid.kendoWidget();
            var checked = $("#masterCheckBoxFilter").is(':checked');
            var data = grid._data;
            var splitIdArray =  this.hf_ID_Filter.split(';');

            if (checked) {
                for (var i = 0; i < data.length; i++){
                    var datarow = data[i]
                    var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbFilter")[0];
                    var dataItem =  datarow.item_id + "|" + datarow.product_code + "|" + datarow.item_name + "|" + datarow.base_uom; 

                    if (elementRow != null) {
                        if (checked && splitIdArray.indexOf(dataItem) == -1) {
                            elementRow.checked = true;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                        }
                        else
                        {
                            elementRow.checked = false;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                        }
                    }
                }
            }
            else {
                for (var i = 0; i < data.length; i++){
                    var datarow = data[i]
                    var elementRow =  grid.tbody.find("tr[data-uid='" + datarow.uid+  "']").find(".cbFilter")[0];
                    var dataItem = datarow.item_id + "|" + datarow.product_code + "|" + datarow.item_name + "|" + datarow.base_uom; 
                    
                    if (elementRow != null) {
                        if (splitIdArray.indexOf(dataItem) == -1) {
                            elementRow.checked = false;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                        }
                        else 
                        {
                            elementRow.checked = true;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                        }
                    }
                }
            }
        },
    }
}
</script>
