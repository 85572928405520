import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class MachineTypeServices {
    readQuery(){
        var query = `
            query ($paging:ServerPaging) {
                GetMachineType (Paging:$paging) {
                    machine_type {
                        created_at
                        last_update
                        machine_type_id
                        machine_type_code
                        machine_type_name
                        created_by
                    }
                    total
                }
            }
        `;
        return query;
    }
    
    async GetMachineType(id) {
        const variables = {
            id : id,
        }
        var query = gql`
            query ($id:Int) {                
                GetMachineType (machineTypeID:$id) {
                    machine_type {
                        created_at
                        last_update
                        machine_type_id
                        machine_type_code
                        machine_type_name
                        created_by
                        machine_type_details {
                            created_at
                            last_update
                            machine_type_detail_id
                            machine_type_id
                            type_detail_id
                            type_detail_name
                        }
                    }
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetMachineType.machine_type[0];
    }
    
    GetTypeDetailDataQuery(){
        var query = `
            query {
                GetTypeDetail {
                    type_detail_id
                    type_detail_name
                }
            }
        `;
        return query;
    }

    selectedTypeDetailConverter(data) {
        var newData = data.split(";");
        let array = [];
        for (let i = 0; i < newData.length; i++) {
            var splitData = newData[i].split("|")
            var str = {
                machine_type_detail_id: null,
                type_detail_id: splitData[0],
                type_detail_name: splitData[1],
            }            
            array.push(str);
        }

        return array;
    }

    gridDataConverter(data, MachineTypeId){
        let array = [];
        for (let i = 0; i < data.length; i++) {
            var str = {
                machine_type_detail_id: data[i].machine_type_detail_id,
                machine_type_id: MachineTypeId,
                type_detail_id: data[i].type_detail_id,
                type_detail_name: data[i].type_detail_name,
            }
            array.push(str);
        }
        return array;
    }
    
    async addQuery(variables){
        var query = gql`
            mutation ($data:NewMachineType!) {
                CreateMachineType (New:$data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        var query = gql`
            mutation ($id:Int!, $data:NewMachineType!) {
                UpdateMachineType(MachineTypeID:$id, New:$data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async deleteQuery(variables){
        var query = gql`
            mutation ($id:Int!) {
                DeleteMachineType (MachineTypeID:$id)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}

export default new MachineTypeServices();